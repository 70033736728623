<div class="modal-header bg-primary text-white">
  <h5 class="modal-title" id="filterMenuModalLabel">Sort By</h5>
  <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <select name="sortBy" cSelect class="focus-ring focus-ring-light custom-select-with-icons form-control" (change)="onChangeSortBy($event)">
    <option selected disabled value="">Sort by</option>
    <option *ngFor="let sortBy of sortBy" [value]="sortBy.id">{{sortBy.name}}</option>
  </select>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="resetSorts()">Reset</button>
  <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="applySorts()">Filter</button>
</div>